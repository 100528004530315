









































































































































.service-bg {
  background-image: url("../../assets/images/2.jpg");
  background-position: center center;
  background-size: cover;
  Background-attachment: fixed;
}

.Title-group {
  padding: 20px 0 20px 0;
  overflow: hidden;
  text-align: center;

  h2 {
    margin: 0;
    font-size: 16px;
    line-height: 30px;
    height: 30px;
    padding: 5px 0;
    text-align: center;
    color: #ccc;

    span {
      display: inline-block;
      padding: 0;
    }

    .c-black {
      color: #888;
    }
  }

  p {
    line-height: 30px;
    font-size: 12px;
    color: #fff;
  }
  span{
    font-size: 12px;
  }
}

.service-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

}

.sitem {
  display: flex;
  flex-direction: column;
  width: 46%;
  background: #2ca9e1;
  margin-bottom: 10px;
  border-radius: 5px;
  color: white;
  align-items: center;
  padding-bottom: 20px;

  .big-icon {
    border-radius: 50%;
    display: block;
    text-align: center;
    width: 60px;
    height: 60px;
    margin-top: 20px;
    transition: opacity 0.2s linear;
    background: white;

    img {
      width: 60px;
      height: 60px;
    }
  }

  h4 {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;

    span:before {
      content: "\A";
      white-space: pre;
    }

    span {
      font-size: 12px;
    }
  }

  p {
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    span {
      display: flex;
      line-height: 20px;
      text-align: center;
    }
  }
}

.service-item-abt {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 15px;

  h4 {
    align-self: center;
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  img {
    margin: 10px 0px;
  }
}

.contact-btn1 {
  height: 30px;
  line-height: 30px;
  display: inline-block;
  padding: 0 20px;
  font-size: 12px;
  color: #fff !important;
  text-decoration: none;
  background: #0A71FF;
  border-radius: 40px;
  margin-top: 15px;
}

.contact-btn1:active {
  background-color: rgba(221, 66, 80, 1);
  box-shadow: 0 0 0 4px rgba(221, 66, 80, 0.3)
}

.fright {
  float: none;
}

.tr {
  text-align: left;
}

.bg-red {
  background: #0A71FF;
}

.title-sub {
  color: white !important;
}

.title-sub.c-black {
  color: #333 !important;
}

.process-map {
  min-height: 150px;
  background-image: url("../../assets/images/process_map.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0px 20px;
}

.tip-txt {
  text-align: center;
  padding: 40px 20px;
  font-size: 16px;
  line-height: 30px;
  color: #666;
  background-image: url("../../assets/images/dot_tip.png");
  background-repeat: no-repeat;
  background-position: center top;
}

.see-btn-center {
  padding: 60px 0;
  text-align: center;
}

.title-sub-t {
  color: white;
  font-size: 12px;
  opacity: 0.8;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .service-warp {
      border-top: 0px solid #ddd;
    }

    .fright {
      float: right !important;
    }

    .tr {
      text-align: right !important;
    }

    .service-bg {
      background-image: url("../../assets/images/2.jpg");
      background-position: center center;
      background-size: cover;
      Background-attachment: fixed;
    }

    .sabt {
      min-height: 380px;
      border-bottom: 1px solid #ddd;
    }

    .sitembg1 {
      background-image: url("../../assets/images/sitem_bg1.png");
      background-position: right bottom;
      background-repeat: no-repeat;
    }

    .sitembg2 {
      background-image: url("../../assets/images/sitem_bg2.png");
      background-position: left center;
      background-repeat: no-repeat;
    }

    .sitembg3 {
      background-image: url("../../assets/images/sitem_bg3.png");
      background-position: right center;
      background-repeat: no-repeat;
    }

    .sitembg4 {
      background-image: url("../../assets/images/sitem_bg4.png");
      background-position: left center;
      background-repeat: no-repeat;
    }

    .w1000 {
      width: 1000px;
      margin: auto;
      overflow: hidden
    }

    .service-item-abt {
      width: 46%;

      img {
        display: none;
      }
    }

    .service-item-abt h4 {
      margin: 0;
      padding: 0;
      font-size: 40px;
      line-height: 50px;
      font-weight: 100;
    }

    .service-item-abt h5 {
      margin: 0 0 10px 0;
      padding: 0;
      font-size: 18px;
      line-height: 30px;
      font-weight: 100;
    }

    .service-item-abt p {
      weight: 420px;
      margin: 0 0 10px 0;
      font-size: 14px;
      line-height: 24px;
      color: #666;
    }

    .service-item-abt .see-btn {
      margin: 40px 0 0 0;
    }

    .service-item-abt .see-btn {
      margin: 40px 0 0 0;
    }

    .Title-group {
      padding: 0 0 40px 0;
      overflow: hidden;
      text-align: center;
    }

    .Title-group h2 {
      margin: 0;
      font-size: 26px;
      font-weight: 100;
      line-height: 40px;
      height: 40px;
      padding: 5px 0;
      text-align: center;
      color: #ccc;
    }

    .Title-group h2 span {
      display: inline-block;
      padding: 0;
    }

    .Title-group p {
      font-size: 16px;
      line-height: 30px;
      color: #888;
    }

    .Title-group .animate-btn {
      float: right;
    }

    .Title-group .animate-btn a {
      margin: 5px;
    }


    .Title-group h2.title-sub {
      font-size: 44px;
      color: #fff;
      height: 50px;
      font-weight: 100;
    }

    .Title-group h2.c-black {
      color: #121212 !important;
    }

    .Title-group span {
      display: block;
      font-size: 22px;
      opacity: 0.87 !important;
      color: #121212 !important;
      font-weight: 100;

      &.title-sub-t {
        color: white !important;
        opacity: 0.8 !important;
      }
    }

    .Title-group p.sub-tit {
      font-size: 22px;
      color: #fff;
    }

    .see-btn-center {
      padding: 60px 0;
      text-align: center;
    }

    .see-btn-center .contact-btn1 {

    }

    .see-btn-center2 {
      padding: 20px 0;
      text-align: center;
    }

    .see-btn-center2 .contact-btn1 {
      padding: 5px 60px;
      font-size: 26px;
    }

    .tip-txt {
      text-align: center;
      padding: 40px 20px;
      font-size: 18px;
      line-height: 30px;
      color: #666;
      background-image: url("../../assets/images/dot_tip.png");
      background-repeat: no-repeat;
      background-position: center top;
    }

    .bg-red {
      background: #0A71FF;
    }

    .service-item {
      overflow: hidden;
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
    }


    .sitem {
      display: block;
      float: left;
      width: 21%;
      margin: 2%;
      background: #2ca9e1;
      -moz-transition: all 300ms;
      -o-transition: all 300ms;
      -webkit-transition: all 300ms;
      transition: all 300ms;
      padding-bottom: 0px !important;
    }

    .sitem h4 {
      margin: 20px auto;
      font-size: 30px !important;
      font-weight: 100 !important;
      color: #fff;
      text-align: center;
    }

    .sitem h4 span {
      display: block;
      font-size: 16px !important;
    }

    .sitem h4 em {
      display: block;
      margin: 0 auto;
      height: 100px;
      white-space: nowrap;
    }

    .sitem p {
      padding: 5px 20px 10px;
      text-align: center;
      font-size: 16px !important;
      line-height: 30px !important;
      color: #fff;
      height: 140px;
    }

    .sitem p span {
      display: inline-block;
      margin: 0 10px;
      line-height: 30px !important;
    }

    .contact-btn1 {
      box-shadow: 0 0 0 1px rgba(221, 66, 80, 0);
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .contact-btn1, .contact-btn1:hover {
      height: 40px;
      line-height: 40px;
      display: inline-block;
      padding: 0 40px;
      font-size: 20px;
      color: #fff;
      text-decoration: none;
      background: #0A71FF;
      background-color: #0A71FF;
      border-radius: 40px;
    }

    .contact-btn1:hover {
      background-color: rgba(221, 66, 80, 1);
      box-shadow: 0 0 0 4px rgba(221, 66, 80, 0.3)
    }

    .process-map {
      padding: 60px 0;
    }

    .process-map {
      min-height: 250px;
      background-image: url("../../assets/images/process_map.png");
      background-position: center;
      background-repeat: no-repeat;
    }

    /*图片旋转*/
    .bg {
      background: #FFF;
      margin: 0 auto;
    }

    .bg:hover {
      background: #2FD0B5;
      transition: background-color 0.3s linear 0s;
    }

    .big-icon, .big-icon:hover {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      display: block;
      text-align: center;
      width: 96px!important;
      height: 96px!important;
      margin-top: 20px;
      -webkit-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }

    .sitem a .big-icon i {
      -webkit-transition: -webkit-transform .3s ease-out;
      -moz-transition: -moz-transform .3s ease-out;
      -o-transition: -o-transform .3s ease-out;
      transition: transform .3s ease-out;
    }

    .sitem a:hover .big-icon i {
      -webkit-transform: scale(1) rotate(75deg);
      -moz-transform: scale(1) rotate(75deg);
      -o-transform: scale(1) rotate(75deg);
      -ms-transform: scale(1) rotate(75deg);
      transform: scale(1) rotate(75deg);
      opacity: 0.7;
    }

    .sitem a .big-icon h1 {
      margin-top: 10px;
      color: #c9c9c9;
      font-size: 22px;
    }

    .sitem a:hover .big-icon h1 {
      color: #3999d0;
    }
    .sitem .big-icon img{
      width: 96px !important;
      height: 96px !important;
    }
  }
}

@primary-color: #1DA57A;