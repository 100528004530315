









































.pages {
  margin-top: 20px;
  margin-bottom: 5px;
  height: 40px;
  padding-bottom: 10px;
  text-align: center;
}

.pages ul {
  margin: 0 auto;
}

.pages .firstPage {
  width: 30px;
}

.pages ul li {
  line-height: 25px;
  height: 25px;
  margin-left: 5px;
  font-size: 12px;
  border: 1px solid #E9E9E9;
  padding: 0px 10px;
  width: auto;
  display: inline-block;
  a{
    color: #333;
    text-decoration: none;
  }
}

.pages ul li a:hover {
  color: #0A71FF;
  text-decoration: none;
}

.pages ul li.page-active, .pages ul li.page-active a, .pages ul li.page-active a:hover {
  background-color: #0A71FF;
  color: #FFF
}

.pages .pageinfo {
  line-height: 25px;
  padding: 12px 0px 10px 5px;
  color: #999;
}

.pages select {
  line-height: 25px;
  margin-top: 4px
}

.pages .pageinfo strong {
  color: #555;
  font-weight: normal;
  margin: 0px 2px;
}

@primary-color: #1DA57A;