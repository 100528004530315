

























































//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .mright {
      float: left;
      width: 256px;
      border: 1px solid #ebebeb;
      margin-left: 20px;
      background-color: #FFF;
      padding: 10px;
      box-sizing: content-box;
      overflow: hidden;
    }

    .mright ul li span a:hover {
      margin-left: 10px;
      position: relative;
    }

    .part1 {
      float: left;
      width: 256px;
    }

    .part1 .hdp {
      float: left;
      width: 256px;
      height: 236px;
    }

    .part1 .hdp .flash {
      float: left;
      width: 256px;
      height: 236px;
      overflow: hidden;
    }

    .part1 .hdp .flash img {
      width: 256px;
      height: 200px;
      border: none;
    }

    .part1 .hdp .flash .sub_nav {
      float: left;
      z-index: 200;
      width: 256px;
      color: #999999;
      height: 20px;
      padding-top: 10px;
      overflow: hidden;
    }

    .part1 .hdp .flash .sub_nav .sub_no {
      float: left;
      height: 25px;
      font-weight: bolder;
    }

    .part1 .hdp .flash .sub_nav .sub_no ul {
      float: left;
      width: 156px;
      height: 20px;
      padding-left: 100px;
    }

    .part1 .hdp .flash .picbox {
      overflow: hidden;
      width: 256px;
      position: relative;
      height: 200px;
    }

    .part1 .hdp .flash .picbox div {
      margin-left: -4px;
      width: 100000px;
    }

    .part1 .hdp .flash .picbox dl {
      float: left;
      overflow: hidden;
      width: 256px;
      position: relative;
      height: 200px;
    }

    .part1 .hdp .flash .picbox dt {
      left: 0px;
      width: 256px;
      position: absolute;
      top: 0px;
      height: 200px
    }

    .part1 .mtbd {
      float: left;
      width: 256px;
      height: 322px;
      overflow: hidden;
    }

    .part2 {
      float: left;
      width: 256px;
      height: 32px;
      background: url("../../assets/images/part2bg.jpg") bottom no-repeat
    }

    .part2 span {
      float: left;
      width: 239px;
      font-size: 20px;
    }

    .part2 span a {
      color: #5c5c5c;
    }

    .part2 span a:hover {
      color: #da8600;
      text-decoration: none;
    }

    .part2 img {
      float: left;
      width: 17px;
      height: 17px;
      padding-top: 5px;
    }

    .part3 {
      float: left;
      width: 256px;
      padding-bottom: 20px;
     
      img{
        width: 120px;
        aspect-ratio: auto 120 / 90;
        height: 90px;
        margin-bottom: 10px;
      }
    }

    .part3 .zxal {
      float: left;
      width: 120px;
      text-align: center;
      overflow: hidden;
      padding: 10px 4px 0px 4px;
      box-sizing: content-box;
      margin-bottom: 10px;
      p {
        a {
          font-size: 14px;
          color: #121212; 
        }
      }
    }

    .part3 .algg {
      float: left;
      width: 16px;
      height: 110px;
    }

    .part4 {
      float: left;
      width: 256px;
      font-size: 12px;
      ul>li>a{
        color: #121212;
      }
    }

    .part4 li {
      float: left;
      width: 256px;
      height: 14px;
      padding-top: 15px;
      padding-bottom: 5px;
      font-family: "宋体";
      color: #868686;
      box-sizing: content-box;
      overflow: hidden;
    }

    .part4 span {
      float: left;
      width: 100%;
    }

    .part4 span a {
      color: #5c5c5c;
    }

    .part4 span a:hover {
      color: #0A71FF;
    }
  }
}

@primary-color: #1DA57A;