



























































































































































































































































































.base-info {
  font-size: 13px;
  color: #333;
  line-height: 1.7;
}

.title {
  font-size: 15px;
  color: #284967;
  background-color: rgba(40, 73, 103, 0.12);
  font-weight: 700;
}

@primary-color: #1DA57A;