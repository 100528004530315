






































































































































































































































.translucent-nav-content{
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 15px;
  h2{
    align-self: center;
    font-size: 16px;
  }
}
.contact-list{
  img{
    width: 100%;
    height: auto;
    margin:10px 0px;
  }
}
//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .translucent-nav-block {
      padding-top: 100px;
      width: 100%;
      height: auto;
    }

    .translucent-nav-block .translucent-nav-content {
      width: 1200px;
      background-color: #FFF;
      margin: 20px auto 0;
      display: block;
      padding:0px;
      h2{
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
    }

    .contact-bar {
      margin-top: 16px;
      padding: 0 30px 58px;
    }

    .contact-bar .contact-list {
      float: left;
      width: 560px;
      height: 300px;
      margin: 0 5px;
      position: relative;
      overflow: hidden;
    }

    .contact-bar .contact-list .contact-popup {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
    }

    .contact-bar .contact-list .contact-popup .contact-popup-inner {
      width: 100%;
      height: 100%;
      display: table;
    }

    .contact-bar .contact-list .contact-popup .contact-popup-inner .inner-content {
      display: table-cell;
      vertical-align: middle;
      padding: 0 20px;
      color: #222222;
      font-size: 14px;
    }

    .contact-bar .contact-list .contact-popup .contact-popup-inner .inner-content .address-title {
      font-size: 18px;
      margin-bottom: 12px;
    }

    .contact-bar .contact-list .contact-popup .contact-popup-inner .inner-content .tel {
      margin-top: 5px;
    }
  }
}

@primary-color: #1DA57A;