
































































































































































.case-list {
  display: none;
}

.pos-rel {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;

  .prev, .next {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;
    height: 50px;
    line-height: 50px;
    margin-bottom: 0px;
  }
}

.sbtngroup {
  display: none;
}

.bg-gray {
  background: #f8f8f8;
}

.case-content {
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;

    img {
      width: 90%;
      margin-bottom: 10px;
    }
  }
}

.art-infor-con {
  display: flex;
  justify-content: space-between;
  padding: 10px;

  span {
    display: block;
    font-size: 13px;
    line-height: 30px;
    color: #666;
  }

  em {
    display: none;
  }
}

.title-index {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 0px;
  padding: 20px 0px;
}

.mobile-item {
  display: flex;
  background: white;
  flex-direction: column;
  width: 96%;
  margin: 0 auto 15px;
  box-shadow: 0 0 10px 0 rgb(53 67 78 / 8%);
  border-radius: 6px;

  img {
    display: block;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}

.mobile-text {
  padding: 10px;

  a {
    display: block;

    h4 {
      color: #333;
      font-size: 14px;
      line-height: 40px;
      margin: 0;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  p {
    display: flex;
    justify-content: space-between;
    color: #999;
    font-size: 12px;
  }
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .mobile-container {
      display: none;
    }

    .case-sort {
      box-shadow: 0 0 3px #ddd;
      overflow: hidden;
      padding: 30px 0;
      text-align: center;
      z-index: 999;
    }

    .pos-rel {
      position: relative;
      display: block;

      .prev, .next {
        display: none;
      }
    }

    .clients-list li {
      float: left;
      width: 20%;
      height: 50px;
      margin: 10px 0;
    }

    .clients-list li img {
      display: block;
      margin: 0 auto;
    }

    .c-redbox {
      text-align: center;
      display: block;
      font-size: 2em;
      color: #0A71FF;
    }

    .case-sort h3 {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 44px;
      line-height: 44px;
      font-size: 30px;
      font-weight: 100;
      color: #333;
      text-align: center;
    }

    .case-sort h4 {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 44px;
      line-height: 44px;
      font-size: 30px;
      font-weight: 100;
      color: #333;
    }

    .case-sort h4 span {
      color: #0A71FF;
    }

    .sbtngroup {
      display: block;
      position: absolute;
      right: 10px;
      top: 0px;
    }

    .sbtngroup a {
      display: block;
      float: left;
      text-indent: -9999px;
      width: 44px;
      height: 44px;
      border: 1px solid #ddd;
      background-color: #fff;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      text-decoration: none;
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .sbtngroup a:hover {
      background-color: #f8f8f8;
    }

    .sbtngroup a.all {
      background-image: url("../../assets/images/sbtn_bg.png");
      background-position: 0px 0px;
    }

    .sbtngroup a:hover.all {
      background-position: -44px 0;
    }

    .sbtngroup a[rel~="prev"] {
      background-image: url("../../assets/images/sbtn_bg.png");
      background-position: 0px -88px;
      font-size: 0
    }

    .sbtngroup a:hover[rel~="prev"] {
      background-position: -44px -88px;
    }

    .sbtngroup a[rel~="next"] {
      background-image: url("../../assets/images/sbtn_bg.png");
      background-position: 0px -44px;
      font-size: 0
    }

    .sbtngroup a:hover[rel~="next"] {
      background-position: -44px -44px;
    }

    .case-content {
      min-width: 960px;
      max-width: 1180px;
    }

    .case-content p {
      margin: 0 0 20px 0;
      font-size: 1.2em;
      line-height: 2em;
      text-align: center;
      color: #565656;
    }

    .case-content img {
      display: block;
      margin: 20px auto;
      max-width: 100%;
      height: auto;
    }

    .art-infor {
      height: 80px;
      padding: 20px 0;
      background: #f4f4f4;
    }

    .art-infor-con {
      text-align: right;
      overflow: hidden;
      line-height: 30px;
      padding: 7px 0;
      font-size: 14px;
      color: #999;
    }

    .art-infor-con em {
      display: inline-block;
      color: #0A71FF;
      padding: 0 12px;
      height: 30px;
      background: #ededed;
      border: 1px solid #ddd;
      margin-left: 10px;
      color: #999;
    }

    .art-infor-con em label {
      color: #0A71FF;
      margin-right: 4px;
    }

    .art-infor-con span {
      display: block;
      float: left;
      width: auto;
      text-align: left;
      font-size: 16px;
      line-height: 30px;
      color: #666;
    }

    .art-infor-con a {
      display: block;
      color: #999;
      text-decoration: none;
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .art-infor-con a:hover {
      color: #0A71FF;
    }

    .Title-group {
      padding: 0 0 40px 0;
      overflow: hidden;
      text-align: center;
    }

    .Title-group h2 {
      margin: 0;
      font-size: 26px;
      font-weight: 100;
      line-height: 40px;
      height: 40px;
      padding: 5px 0;
      text-align: center;
      color: #ccc;
    }

    .Title-group h2 span {
      display: inline-block;
      padding: 0;
    }

    .Title-group p {
      font-size: 16px;
      line-height: 30px;
      color: #888;
    }

    .Title-group .animate-btn {
      float: right;
    }

    .Title-group .animate-btn a {
      margin: 5px;
    }

    .Title-group h2.title-sub {
      font-size: 44px;
      color: #fff;
      height: 90px;
      font-weight: 100;
    }

    .Title-group h2.c-black {
      color: #121212;
    }

    .Title-group h2.title-sub span {
      display: block;
      font-size: 22px;
      opacity: 0.87;
    }

    .Title-group p.sub-tit {
      font-size: 22px;
      color: #fff;
    }

    .case-list {
      margin-left: -0.8333%;
      margin-right: -0.8333%;
      overflow: hidden;
      display: block;
    }

    .case-list li, .case-list article {
      float: left;
      width: 23.3333%;
      margin: 0.8333%;
      background: #fff;
    }

    .citem a {
      position: relative;
      display: block;
      text-decoration: none;
    }

    .citem a span {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0);
      background-position: top center;
      background-repeat: no-repeat;
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .citem a img {
      display: block;
      width: 100%;
      height: 64.2857%;
    }

    .citem a:hover span {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.87);
      background-image: url("../../assets/images/see_bg.png");
      background-repeat: no-repeat;
      background-position: center center;
    }

    .citemtxt {
      padding: 3.571429% 7.1429%;
    }

    .citemtxt a {
      text-decoration: none;
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .citemtxt a h4 {
      margin: 0;
      padding: 0;
      color: #666;
      font-size: 14px;
      line-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .citemtxt a:hover h4 {
      color: #0A71FF;
    }

    .citemtxt p {
      overflow: hidden;
      text-align: right;
      font-size: 12px;
      line-height: 20px;
      color: #999;
    }

    .citemtxt p span {
      float: left;
      width: auto;
      text-align: left;
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .citemtxt p span a {
      color: #0A71FF;
    }

    .citemtxt p a {
      display: inline-block;
      color: #999;
    }

    .case-content {
      div {
        textAlign: "center";

        img {
          width: 750px;
        }
      }
    }
  }
}


@primary-color: #1DA57A;