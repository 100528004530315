





























































.new_index_news {
  display: none;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .new_index_news {
      display: block;
      padding-top: 155px;
      background: url("../../assets/images/new_index_news_bg.jpg") no-repeat;
      background-size: cover;
    }
    .service-container{
      padding: 30px 0 !important;
    }
    .new_index_news .new_news_box {
      padding-top: 30px;
    }

    .new_index_news .new_news_box .new_news_recommend {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .new_index_news .new_news_box .new_news_recommend .recommend_news_item {
      width: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .new_index_news .new_news_box .new_news_recommend .recommend_news_item:hover .right_info h5 {
      color: #0A71FF;
    }

    .new_index_news .new_news_box .new_news_recommend .recommend_news_item:not(:last-child) {
      margin-right: 50px;
    }

    .new_index_news .new_news_box .new_news_recommend .recommend_news_item .left_img {
      width: 200px;
      height: 150px;
      border-radius: 10px;
      overflow: hidden;
    }

    .new_index_news .new_news_box .new_news_recommend .recommend_news_item .left_img img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    .new_index_news .new_news_box .new_news_recommend .recommend_news_item .right_info {
      width: calc(100% - 220px);
    }

    .new_index_news .new_news_box .new_news_recommend .recommend_news_item .right_info h5 {
      font-size: 16px;
      font-weight: 500;
      color: #333;
      line-height: 24px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }

    .new_index_news .new_news_box .new_news_recommend .recommend_news_item .right_info .info_date {
      margin: 10px 0 15px;
      font-size: 16px;
      color: #999;
      line-height: 24px;
    }

    .new_index_news .new_news_box .new_news_recommend .recommend_news_item .right_info p {
      font-size: 14px;
      color: #666;
      line-height: 20px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }

    .new_index_news .new_news_box ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding-top: 50px;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .new_index_news .new_news_box ul li {
      padding: 15px 0;
      width: 47.9%;
      margin-right: 50px;
    }

    .new_index_news .new_news_box ul li:nth-child(2n) {
      margin-right: 0;
    }

    .new_index_news .new_news_box ul li a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .new_index_news .new_news_box ul li a:hover .right_info h5 p {
      color: #0A71FF;
    }

    .new_index_news .new_news_box ul li a .left_number {
      margin-right: 15px;
      padding: 0 4px;
      width: 30px;
      line-height: 25px;
      font-size: 16px;
      font-weight: bold;
      color: #fefefe;
      background: #b1b8be;
    }

    .new_index_news .new_news_box ul li a .right_info {
      border-bottom: 1px solid #d6dce0;
    }

    .new_index_news .new_news_box ul li a .right_info h5 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .new_index_news .new_news_box ul li a .right_info h5 p {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      color: #333;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }

    .new_index_news .new_news_box ul li a .right_info h5 span {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 400;
      color: #999;
      white-space: nowrap;
    }

    .new_index_news .new_news_box ul li a .right_info p {
      margin: 15px 0 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666;
      line-height: 20px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }
  }
}

@primary-color: #1DA57A;