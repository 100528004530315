






















































































































.main1{
  display: none;
}
.mobile-main{
  display: flex;
  flex-direction: column;
}
.mobile-list{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}


//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .mobile-main{
      display: none;
    }
    .main1 {
      margin: 20px auto; 
      overflow: hidden;
      display: block;
    }

    .mainn {
      margin: 0 auto;
      width: 1000px;
    }

    .mleft {
      width: 680px;
      border: 1px solid #ebebeb;
      background-color: #FFF;
      float: left;
      padding: 0 10px;
      box-sizing: content-box;
    }

    .artlist {
      width: 675px;
      height: 100px;
      border-bottom: 1px dashed #ebebeb;
      padding: 15px 0px;
    }

    .times {
      float: left;
      width: 128px;
      height: 71px;
      font-size: 18px;
      color: #FFF;
      background: url("../../assets/images/times.jpg") no-repeat;
      text-align: center;
      padding-top: 10px;
    }

    .ontimes {
      float: left;
      width: 128px;
      height: 71px;
      font-size: 18px;
      color: #FFF;
      background: url("../../assets/images/times1.jpg") no-repeat;
      text-align: center;
      padding-top: 10px;
    }

    .ontimes a {
      color: #fff;
    }

    .listt {
      float: left;
      width: 530px;
      height: 85px;
      padding-left: 15px;
    }

    .listtbt {
      float: left;
      width: 530px;
      height: 20px;
      font-size: 18px;
    }

    .listtbt a {
      color: #5c5c5c;
    }

    .listtbt a:hover {
      color: #0A71FF;
      margin-left: 10px;
    }

    .listtnr {
      float: left;
      width: 530px;
      height: 45px;
      font-size: 12px;
      font-family: "宋体";
      color: #868686;
      line-height: 20px;
      padding-top: 10px;
      overflow: hidden;
    }

    .fenye {
      float: left;
      width: 850px;
    }

    .next {
      padding-left: 28px;
    }

    .nextpage {
      float: left;
      width: 850px;
      height: 58px;
      text-align: center;
      font-size: 14px;
      padding-top: 36px;
    }

    .nextpage a {
      color: #5c5c5c;
      padding: 4px 7px 2px 7px;
      border: 1px solid #dddddd;
    }

    .nextpage a:hover {
      color: #da8600;
      text-decoration: none;
    }

    

    .leftnr {
      float: left;
      width: 680px;
      height: 100px;
      background: url("../../assets/images/biaoqian.jpg") no-repeat;
      margin-top: 30px;
      border-bottom: 1px dashed #ebebeb;
    }

    .leftnr .yuedu {
      float: left;
      width: 53px;
      height: 70px;
      color: #FFF;
      font-size: 12px;
      font-family: "宋体";
      padding-left: 7px;
      padding-top: 5px;
    }

    .leftnr .leftbt {
      float: left;
      width: 620px;
      height: 30px;
    }

    .leftnr .leftbt h1 {
      float: left;
      width: 620px;
      height: 30px;
      line-height: 30px;
      font-size: 24px;
      text-align: center;
      color: #5c5c5c;
      font-weight: normal;
    }

    .leftnr .leftbt .zuozhe {
      float: left;
      width: 620px;
      height: 40px;
      font-size: 12px;
      text-align: center;
      color: #5c5c5c;
    }

    .left_xx {
      float: left;
      width: 630px;
      height: 1px;
      margin-left: 25px;
      margin-right: 25px;
      border-bottom: 1px dashed #ebebeb;
    }

    .left_content {
      float: left;
      width: 630px;
      padding: 0px 25px 20px 25px;
      line-height: 26px;
      font-family: "宋体";
      color: #5c5c5c;
      font-size: 14px;
    }

    .left_content p {
      padding-top: 20px;
    }

    .left_content .jianjie {
      float: left;
      font-size: 12px;
      font-weight: bold;
      text-indent: 2em;
      padding-top: 20px;
    }

    .left_content .jianjie a {
      color: #5c5c5c;
    }

    .left_content .tag {
      float: left;
      font-size: 12px;
      color: #868686;
    }

    .left_content .tag a {
      color: #5c5c5c;
    }

    .zxal p a {
      text-decoration: none;
      color: #333;
      font-size: 12px;
    }
  }
}

@primary-color: #1DA57A;