






















































.new_cooperate_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @iterations: 8;
  .li-loop (@i) when (@i > 0) {
    li:nth-child(@{i}) {
      background-image: url('../../assets/images/new_index_cooperate_bg@{i}.png');
      background-size: cover !important;
      background-repeat: no-repeat;
      background-position: 100% 100%;
    }
    .li-loop(@i - 1);
  }
  .li-loop (@iterations);

  li {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 45%;
    padding:  20px;
    flex-direction: column;
    border: 1px solid #e7ecf2;
    border-radius: 6px;

    h3 {
      align-items: center;
      color: #333;
      display: flex;
      font-size: 16px;
      justify-content: flex-start;
      margin-bottom: 15px;

      img {
        height: 22px;
        margin-right: 5px;
        object-fit: contain;
        width: 22px;
      }
    }

    p {
      color: #666;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .new_cooperate_list {
      width: 100%;

      li {
        width: 22.5%;
        background-position: 100% 100%;
        background-size: auto !important;
        padding: 20px 30px;
        h3 {
          align-items: center;
          color: #333;
          display: flex;
          font-size: 24px;
          justify-content: flex-start;
          margin-bottom: 15px;

          img {
            height: 45px;
            margin-right: 5px;
            object-fit: contain;
            width: 45px;
          }
        }

        p {
          color: #666;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}


@primary-color: #1DA57A;