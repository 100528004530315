






















































































.industry {
  background-image: url("../../assets/images/new_index_industry_bg.jpg");
  background-size: cover;
}

.new_industry_list {
  display: none;
}

.mobile_new_industry_list {
  display: flex;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    li {
      background: #fff;
      border: 1px solid #eee;
      border-radius: 10px;
      height: 160px;
      margin-bottom: 20px;
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        color: white;
      }

      span {
        display: block;
        margin: 10px auto 0;
        width: 85px;
        font-size: 12px;
        font-weight: 400;
        color: #0A71FF;
        line-height: 30px;
        text-align: center;
        background: -webkit-gradient(linear, left top, right top, from(#EAEFF3), to(#EAEFF3cc));
        background: linear-gradient(90deg, #EAEFF3 0%, #EAEFF3cc 100%); 
        
        border-radius: 5px;
      }


      img {
        height: 60px;
        margin: 0 auto;
        object-fit: contain;
        width: 60px;
      }

      p {
        color: #333;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }

      
    }
  }
}

.new_industry_more {
  margin: 20px auto 0 auto;
  width: 150px;
  font-size: 12px;
  font-weight: 400;
  line-height: 35px;
  color: #0A71FF;
  text-align: center;
  background: #fff;
  border: 1px solid #0A71FF; 
  border-radius: 6px;

  &:active {
    background: #0A71FF;
    color: #fff;
  }
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .mobile_new_industry_list {
      display: none;
    }

    .new_industry_list {
      display: flex;

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;

        li {
          background: #fff;
          border: 1px solid #eee;
          border-radius: 10px;
          height: 285px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 22.5%;

          &:hover {
            background: linear-gradient(147deg, #0A71FF 0%, #2b77f5 100%);
            cursor: pointer;

            p {
              color: white;
            }

            span {
              display: block;
              margin: 30px auto 0;
              width: 135px;
              font-size: 16px;
              font-weight: 400;
              color: #0e6565;
              line-height: 40px;
              text-align: center;
              background: -webkit-gradient(linear, left top, right top, from(#00ffff), to(#bbffff));
              background: linear-gradient(90deg, #00ffff 0%, #bbffff 100%);
              -webkit-box-shadow: 0px 10px 15px 0px rgba(51, 82, 133, 0.2);
              box-shadow: 0px 10px 15px 0px rgba(51, 82, 133, 0.2);
              border-radius: 5px;
            }
          }

          img {
            height: 112px;
            margin: 0 auto;
            object-fit: contain;
            width: 112px;
          }

          p {
            color: #333;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
          }

          span {
            display: none;
          }
        }
      }
    }

    .new_industry_more {
      margin: 50px auto 0 auto;
      width: 220px;
      font-size: 16px;
      font-weight: 400;
      line-height: 55px;
      color: #0A71FF;
      text-align: center;
      background: #fff;
      border: 1px solid #0A71FF;
      -webkit-box-shadow: 0px 10px 15px 0px rgba(51, 82, 133, 0.2);
      box-shadow: 0px 10px 15px 0px rgba(51, 82, 133, 0.2);
      border-radius: 10px;

      &:hover {
        background: #0A71FF;
        color: #fff;
      }
    }
  }
}

@primary-color: #1DA57A;