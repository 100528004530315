






















































































































































.case-warp {
  display: none;
}

.infinite-status-prompt {
  span {
    font-size: 12px;
    color: #888;
  }
}


.mobile-container {
  background: #f8f8f8;
}

.mobile-item {
  display: flex;
  background: white;
  flex-direction: column;
  width: 96%;
  margin: 0 auto 15px;
  box-shadow: 0 0 10px 0 rgb(53 67 78 / 8%);
  border-radius: 6px;

  img {
    display: block;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}

.mobile-text {
  padding: 10px;

  a {
    display: block;

    h4 {
      color: #333;
      font-size: 14px;
      line-height: 40px;
      margin: 0;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  p {
    display: flex;
    justify-content: space-between;
    color: #999;
    font-size: 12px;
  }
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .mobile-container {
      display: none;
    }

    .case-warp {
      display: block;
    }

    .case-list {
      margin-left: -0.8333%;
      margin-right: -0.8333%;
      overflow: hidden;
    }

    .case-list li, .case-list article {
      float: left;
      width: 23.3333%;
      margin: 0.8333%;
      background: #fff;
    }

    .citem a {
      position: relative;
      display: block;
      text-decoration: none;
    }

    .citem a span {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0);
      background-position: top center;
      background-repeat: no-repeat;
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .citem a img {
      display: block;
      width: 100%;
      height: 64.2857%;
    }

    .citem a:hover span {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.87);
      background-image: url("../../assets/images/see_bg.png");
      background-repeat: no-repeat;
      background-position: center center;
    }

    .citemtxt {
      padding: 3.571429% 7.1429%;
    }

    .citemtxt a {
      text-decoration: none;
      -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
      transition: background 0.3s, color 0.3s, box-shadow 0.3s;
    }

    .citemtxt a h4 {
      margin: 0;
      padding: 0;
      color: #666;
      font-size: 14px;
      line-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .citemtxt a:hover h4 {
      color: #0A71FF;
    }

    .citemtxt p {
      overflow: hidden;
      text-align: right;
      font-size: 12px;
      line-height: 20px;
      color: #999;
    }

    .citemtxt p span {
      float: left;
      width: auto;
      text-align: left;
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .citemtxt p span a {
      color: #0A71FF;
    }

    .citemtxt p a {
      display: inline-block;
      color: #999;
    }
  }
}

@primary-color: #1DA57A;