
















.footer-container {
  display: none;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {
    .footer-container {
      display: block;
    }
  }
}

@primary-color: #1DA57A;