




































.item-container {
  display: flex;
  margin: 10px;
  flex-direction: column;
  border-bottom: 1px dashed #ebebeb;

  h3 {
    font-size: 16px;
  }

  span {
    font-size: 12px;
    color: #999;
  }

  .item-content {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

@primary-color: #1DA57A;