

































.new_index_links {
  display: none;
}

//设置媒体查询
@media only screen {
  // 断点 768px
  @media (min-width: 768px) {

    .new_index_links {
      display: block;
    }

    .new_links_list {
      padding-top: 30px;

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          margin-right: 15px;

          a {
            font-size: 14px;
            font-weight: 400;
            color: #333;
            line-height: 28px;
          }

          a:hover {
            color: #0A71FF;
            text-decoration: underline !important;
          }
        }
      }
    }

  }
}


@primary-color: #1DA57A;